.arrow {
  position: absolute;
  overflow: hidden;
  bottom: 0px;
  width: 261px;
  height: 340px;
}

.arrowRight {
  right: 80px;
  top: -60px;
}
.arrowLeft {
  left: 60px;
  bottom: 0px;
  width: 240px;
  height: 250px;
}

.searchContainer {
  background: rgba(0, 0, 0, 0.3);
  max-width: max-content;
  margin: 0 auto;
  .searchTitle {
    z-index: 999;
    color: white;
  }
  .buttonContainer {
    @media (max-width: 960px) {
      width: 100%;
    }
    button {
      height: 100%;
      min-width: 130px;
    }
  }
  .inputContainer {
    @media (max-width: 960px) {
      flex-direction: column;
      flex: 1;
      height: auto;
      .buttonContainer {
        margin: 20px 0;
      }
    }
  }
  .advancedSearch {
    color: white;
  }
}
