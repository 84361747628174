.containerPopular {
  display: flex;
  align-content: center;
  justify-content: space-between;

  nav {
    display: flex;
    justify-content: space-between;
    @media (max-width: 960px) {
      flex-direction: column;
    }
    div {
      margin-top: 10px;
      @media (max-width: 960px) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  ul {
    list-style: none;
    padding: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    li {
      margin: 6px 0 2px;
      display: flex;
      flex-direction: column;
      max-width: max-content;
      transition: 0.2s ease-in-out;
      border-bottom: 1px solid white;
      @media (max-width: 960px) {
        margin: 0;
      }
    }
    li:hover {
      border-bottom: 1px solid #a0a0a0;
    }
  }
  span {
    // color: #fff;
  }
  a {
    text-decoration: none;
    border-bottom: 1px solid white;
  }
  @media (max-width: 1024px) {
    .container {
      padding: 20px 0;
      flex-direction: column;
      text-align: center;
    }

    ul {
      flex-direction: column;
      margin: 0;
      li {
        padding: 10px 0;
      }
    }
    span {
      margin-top: 40px;
    }
  }
  .lang {
    cursor: pointer;
  }
}
