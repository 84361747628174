.container {
  @media (max-width: 960px) {
    width: 80vw;
  }
}

.image {
  width: 100%;
  height: 165px;
  position: relative;
  object-fit: cover;
  transition: 0.3s linear;

  &.imageLoading {
    opacity: 0;
  }
  &.imageLoaded {
    opacity: 1;
    &:hover {
      opacity: 0.8;
    }
  }
}

.verticalImage {
  margin-right: 20px;
  width: 100%;
  height: 150px;

  object-fit: cover;
}

.list {
  list-style: none;
  padding: 0;
  font-size: 0.875rem;
  // font-size: 14px;

  li {
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
  }
}

.loadedImagePlaceholder {
  background-color: #f5f5f5;
  animation: loaderAnim 1s infinite;
}
@keyframes loaderAnim {
  from {
    background-color: #f5f5f5;
  }
  50% {
    background-color: #ececec;
  }
  to {
    background-color: #f5f5f5;
  }
}
